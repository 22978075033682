import React, { useState } from 'react';
import { Button, Popover, Typography } from 'glints-aries/es/@next';
import { FormattedMessage } from 'react-intl';
import { useChannelStateContext } from 'stream-chat-react';
import styled from 'styled-components';

import {
  ApplicationStatus,
  applicationStatusMessages,
} from '../../../common/constants';
import useBulkChangeApplicationStatus from '../../../common/hooks/requests/useBulkChangeApplicationStatus';
import RenderBasedOnApplicationAccessLevel from '../../../components/FullProfileAccess/RenderBasedOnApplicationAccessLevel';
import VipAccessNeededToViewDetails from '../../../components/FullProfileAccess/VipAccessNeededToViewDetails';
import { useGetApplicationById } from '../graphql';
import { getChannelApplicationMetadataV2 } from '../helper';
import { useGetChannelLocation } from '../hooks/useGetChannelLocation';
import useHandleApplicationStatusChangeForChat from '../hooks/useHandleApplicationStatusChangeForChat';
import { trackMessagingApplicationStatusChangeEvent } from '../tracking';
import ApplicationStatusDropdownButton from './ApplicationStatusDropdownButton';

const Styled = {
  MenuOption: styled(Button)`
    border: 0;
    box-shadow: none;
  `,
};

const dropdownOptions = [
  ApplicationStatus.IN_REVIEW,
  ApplicationStatus.ASSESSMENT,
  ApplicationStatus.INTERVIEWING,
  ApplicationStatus.OFFERED,
  ApplicationStatus.HIRED,
];

const ApplicationStatusDropdown = () => {
  const [active, setActive] = useState(false);
  const [isRequestInFlight, setIsRequestInFlight] = useState(false);
  const { channel } = useChannelStateContext();
  const metadata = getChannelApplicationMetadataV2(channel);
  const applicant =
    channel.state.members[metadata.glints_application_applicant_id]?.user;

  const { data } = useGetApplicationById({
    variables: {
      id: metadata.glints_application_id,
    },
    fetchPolicy: 'cache-first',
  });

  const application = data?.getApplicationById;

  const updateStatus = useBulkChangeApplicationStatus({
    jobId: metadata.glints_job_id,
  });

  const handleButtonOnClick = () => setActive(state => !state);

  const { handleApplicationStatusChangeForChat } =
    useHandleApplicationStatusChangeForChat();

  const { channelLocation } = useGetChannelLocation();

  const makeUpdateStatusHandler = (status: ApplicationStatus) => async () => {
    setActive(false);
    setIsRequestInFlight(true);

    try {
      const result = await updateStatus(
        [
          {
            applicationId: metadata.glints_application_id,
            name: applicant?.name ?? '',
            status,
          },
        ],
        {
          eagerUpdateChannel: true,
        }
      );

      if (result && !result.failed.length) {
        if (application) {
          trackMessagingApplicationStatusChangeEvent({
            messagingPayload: {
              jobId: metadata.glints_job_id,
              companyId: metadata.glints_company_id,
              channelId: channel.id,
              channelMembersCount: channel.data?.member_count,
              fromApplicationStatus: metadata.glints_application_status,
              toApplicationStatus: status,
            },
            waPresent: application.whatsAppDetails.isAvailable,
          });
        }

        handleApplicationStatusChangeForChat(result);
      }
    } finally {
      setIsRequestInFlight(false);
    }
  };

  const activator = (
    <ApplicationStatusDropdownButton
      status={metadata.glints_application_status}
      isLoading={isRequestInFlight}
      onClick={handleButtonOnClick}
    />
  );
  if (!application) return null;

  return (
    <RenderBasedOnApplicationAccessLevel
      applicationDetails={application}
      jobId={application.job.id}
      vipCTAClickedTrackingLocation="skip"
      renderVipAccessContent={({ initialBalance }) => (
        <VipAccessNeededToViewDetails.PopoverAccessLimit
          initialBalance={initialBalance}
          vipCTAClickedTrackingLocation={`${channelLocation}_move_to`}
          activator={
            <ApplicationStatusDropdownButton
              status={metadata.glints_application_status}
              isDisabled={true}
            />
          }
        />
      )}
    >
      <Popover
        active={active}
        preferredPosition="below"
        preferredAlignment="right"
        onClose={() => setActive(false)}
        activator={activator}
      >
        <Popover.Pane>
          {dropdownOptions.map(value => (
            <Styled.MenuOption
              key={value}
              fullWidth={true}
              disabled={value === metadata.glints_application_status}
              onClick={makeUpdateStatusHandler(value)}
            >
              <Typography variant="body1" style={{ whiteSpace: 'nowrap' }}>
                <FormattedMessage {...applicationStatusMessages[value]} />
              </Typography>
            </Styled.MenuOption>
          ))}
        </Popover.Pane>
      </Popover>
    </RenderBasedOnApplicationAccessLevel>
  );
};

export default ApplicationStatusDropdown;
