import React from 'react';
import { Button, Icon, Typography } from 'glints-aries/es/@next';
import { Neutral } from 'glints-aries/es/@next/utilities/colors';
import { FormattedMessage } from 'react-intl';

import { ApplicationDetails } from '../../../../common/interfaces/entities';
import RenderBasedOnApplicationAccessLevel from '../../../../components/FullProfileAccess/RenderBasedOnApplicationAccessLevel';
import VipAccessNeededToViewDetails from '../../../../components/FullProfileAccess/VipAccessNeededToViewDetails';
import RejectApplicationButton from '../../../../components/RejectApplicationButton/RejectApplicationButton';
import useGetApplicationDetails from '../../../EmployerATSV2/components/ApplicationTable/ApplicationDetailsModal/hooks/useGetApplicationDetails';
import { useGetChannelLocation } from '../../hooks/useGetChannelLocation';
import useHandleApplicationStatusChangeForChat from '../../hooks/useHandleApplicationStatusChangeForChat';

type RejectApplicationMenuButtonProps = {
  application: ApplicationDetails;
};

export default function RejectApplicationMenuButton({
  application,
}: RejectApplicationMenuButtonProps) {
  const { JobId: jobId = '', id: applicationId } = application;
  const { handleApplicationStatusChangeForChat } =
    useHandleApplicationStatusChangeForChat();
  const { application: applicationDetails } = useGetApplicationDetails({
    applicationId: applicationId,
    jobId: jobId,
  });

  const { channelLocation } = useGetChannelLocation();

  return (
    <RenderBasedOnApplicationAccessLevel
      applicationDetails={applicationDetails}
      jobId={jobId}
      vipCTAClickedTrackingLocation="skip"
      renderVipAccessContent={({ initialBalance }) => (
        <VipAccessNeededToViewDetails.PopoverAccessLimit
          initialBalance={initialBalance}
          vipCTAClickedTrackingLocation={`${channelLocation}_reject`}
          activator={
            <Button icon={<Icon name="ri-close" />} disabled={true}>
              <Typography variant="button">
                <FormattedMessage
                  id="interactive-reject"
                  defaultMessage="Reject"
                />
              </Typography>
            </Button>
          }
        />
      )}
    >
      <RejectApplicationButton
        jobId={jobId}
        selectedApplications={[applicationDetails]}
        icon={<Icon name="ri-close" />}
        preferredAlignment="right"
        preferredPosition="below"
        eagerUpdateChannel={true}
        onRejectSuccess={handleApplicationStatusChangeForChat}
      >
        <Typography variant="button" color={Neutral.B18}>
          <FormattedMessage id="interactive-reject" defaultMessage="Reject" />
        </Typography>
      </RejectApplicationButton>
    </RenderBasedOnApplicationAccessLevel>
  );
}
